import React from 'react';
import {Carousel } from 'antd';
import { images } from '../../../constants';
import Hero1 from './Hero1';

const Hero = () => {
  const  herotitle1= "de tigers"
  const  herotext1= 
  "De Tigers Security Network and Integrated Services Limited is a private security company that specializes in Critical Infrastructure Protection."
  const  herotitle2= "High-Performing"
  const  herotext2= "With De Tigers, you are very certain of quality service, professional conduct, and  innovative solutions to security needs across several environments for commercial,  institutional, residential and governmental clientele."
  const  herotitle3= "Secure"
  const  herotext3= "Our Company was borne out of the need to address the growing rate of global  insecurity and to improve business service provision for the protection of lives and  property."
  return (
  <section className=' text-white pb-10 mb-10px md:mb-[20px] lg:mb-[100px]  bg-gradient-to-t from-[#000000] to-[#333333]'>
    <Carousel 
    autoplay
    draggable={true} 
    dots={true}
    dotPosition='bottom'
    autoplaySpeed={5000}>
    <Hero1 image={images.tiger} text={herotext1} title={herotitle1}/>
    <Hero1 image={images.ladyincar} text={herotext2} title={herotitle2}/>
    <Hero1 image={images.man} text={herotext3} title={herotitle3}/>
   </Carousel>
    </section>

  )
}

export default Hero
