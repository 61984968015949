const ImageslideSkeleton = ({ className }) => {
    return (
      <div
        className={`rounded-xl overflow-hidden shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] ${className} animate-pulse`}
      >
            <div  className="" >
            <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            <div
            className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none "
            >
            <div className=" w-full aspect-video bg-slate-300 rounded-lg" />
            <div className='mt-3 h-5 rounded-lg bg-slate-300 '/>
               
            </div>
            </div>
            </div>
        </div>
        )
        }
    export default ImageslideSkeleton;
    