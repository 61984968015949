import React from 'react';
import MainLayout from '../../../components/MainLayout';
import { images } from '../../../constants';

const TeamCard = () => {
  return (
    <MainLayout>
<div className="bg-gradient-to-tr from-[#333333] to-neutral-300/90 ">
    <div className="py-4 mx-auto">
        <div className="text-center mb-10">
            <p className="mt-4 text-sm leading-7 text-gray-700 font-regular">
                THE TEAM
            </p>
            <h3 className="lg:text-5xl font-sans text-4xl leading-normal font-bold text-gray-900">
                Our<span className="text-eni_orange"> Team</span>
            </h3>
        </div>


        <div className="grid md:grid-cols-3  gap-6 md:gap-3 max-w-[95vw] mx-auto">

            <div className="text-center">
                <img className="w-100 lg:rounded-bl-full" src={images.meetingroom} alt='team'/>
            </div>

            <div className='h-full text-justify'>
                <p>
                Our company is composed of a team of motivated, dedicated, and experienced experts at various levels of the organizational hierarchy.

                <br/> <br/>The work carried out by these professionals in various functional areas is geared towards the attainment of the organizational vision, mission, and core values.
                <br/><br/>Through teamwork, we strive to continuously strengthen our relationship with customers, improve business profitability, and increase shareholders’ value while delivering efficient and resilience service.
                </p>
            </div>
            <div className="text-center">
                <img className="w-100 rounded-lg shadow-2xl hover:scale-105 duration-100" src={images.organogram} alt='team'/>
            </div>
           

        </div>

    </div>
    </div>

    </MainLayout>
    
  )
}

export default TeamCard