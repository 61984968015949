import React from "react";

const Admin = () => {
  return <div className="text-gray-600">Admin dashboard

    <div> 
      <div className=" border-2 border-neutral-400 rounded-3xl p-2 mt-2 text-center"> Manage All latest news and updates here </div>
    </div>
  </div>;
};

export default Admin;
