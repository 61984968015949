import { motion } from "framer-motion";
//import { images } from "../../../constants";
import { TypeAnimation } from "react-type-animation";
import { HiArrowNarrowDown } from "react-icons/hi";
import { Link } from "react-router-dom";
import {Link as Linkroll} from "react-scroll";
const containerVariants = {
  animate: {
    transition: {
      delayChildren: 2,
      staggerChildren: 0.6,
    },
  },
};

const Hero = ({image, title, text}) => {
  return (
    <div className="flex flex-col lg:flex-row mt-6  items-start lg:items-start justify-between max-[960px]:flex-col px-32 max-[960px]:px-16 max-[560px]:px-8 ">
      <div className="lg:mr-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5 }}
          className="w-full "
        >
          <div className="flex">
            <h1 className="mb-4 font-sans text-eni_orange text-5xl uppercase font-semibold max-[960px]:text-4xl max-[560px]:text-4xl">
              {title}
            </h1>
          </div>

          <div className="font-semibold text-sm text-neutral-200 lg:text-2xl lg:mb-6" style={{ maxWidth: '400px' }}>
            <TypeAnimation
              sequence={[
                "Security Network & Integrated Services Ltd.",
                1000,
                "We are Innovative and reliable",
                1000,
                "High-performing security company",
                1000,
                "Delivers timely and quality services for assets protection",
                1000,
      
              ]}
              wrapper="span"
              speed={50}
              style={{  display: "inline-block" }}
              repeat={Infinity}
            />
          </div>
          <p className="py-2 text-base text-neutral-400  tracking-wider mt-4 text-justify" style={{ maxWidth: '400px', maxHeight: '200px' }}>
          {text}
          </p>
          
            <div className="mt-6 flex items-center space-x-6">
            <Link to="/contact" spy={true} smooth={true} offset={-100}>
            <button className="py-4 rounded-lg bg-eni_orange border border-eni_orange font-semibold text-white w-[12vw] max-[1080px]:w-[16vw] max-[800px]:w-[20vw] max-[650px]:w-[30vw] max-[500px]:w-[38vw] max-[340px]:w-[42vw] px-4 text-md uppercase ">
               GET STARTED
            </button>
            </Link>
            <Linkroll to="services" spy={true} smooth={true} offset={-100}>
            <div className="flex items-center">
              <button className="text-neutral-500 font-bold text-md uppercase">
               Explore our services ?
              </button>
              <HiArrowNarrowDown className="text-eni_orange text-2xl ml-2 animate-bounce" />
            </div>
            </Linkroll>
          </div>
        </motion.div>
      </div>
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        className="mt-6  justify-center"
      >
        <img src={image} className="lg:h-[350px] lg:w-[500px] rounded-lg" alt='dts_' />
      </motion.div>
    </div>
  );
};

export default Hero;
