import React, { useState } from 'react'
import AccordianItems from "./AccordianItems";
import {motion} from "framer-motion";


const Faq = () => {
    const [open, setOpen] = useState(false)

  const toggle = (index) => {
    if (open === index) {
      return setOpen(null)
    }

    setOpen(index)
  }

    const accordianData = [
       
        {
            title: "Do you have a physical office space ?",
            desc: `Yes. Our office is situated at 39/40 Peter Odili Road, Trans Amadi Industrial Layout, Port Harcourt`,
                                            
          },
        {
          title: "Do you offer close protection services ?",
          desc: `We also provide close protection
          (bodyguard) services for VIPs/Executives
          and their families on contract basics or
          during one-time trips within certain areas.
          This package also includes, negotiations
          for full spectrum transportation
          (bulletproof/regular vehicles) and armed
          support by conventional security agencies`,
        },
        
        {
          title: "What are the price range for these services",
          desc:
            
             `Kindly refer to our contact page, where you'll find dedicated phone numbers and email to make tailored enquires`
            }, 
         {
           title: "What sectors do you render frequent services to ?",
           desc: `We offer a wide range of services covering different
           public and private sector customers within a variety of
           industries including:
           * Oil &Gas
           * Telecommunications
           * Education
           * Healthcare
           * Housing &Residential
           * Commercial
           * Etc.`,
        },
    
        {
          title: "What is the certification and skill levels of your personnels ?",
          desc: `Our personnel posses the necsssary
          knowlegde, skills, and attitude, (KSAs)
          to provide a quality guard service for the
          protection of organizational assets
          (People, Property, Information)`,
        },
    
        {
           title: "How long have you been in business ?",
           desc: `De tigers security Network & Integrated services is a new security company birthed in 2023 and has since been providing topnotch services`,
        }
     
      ]


  return (
    <motion.div
    initial={{ x: -300, opacity: 0 }}
    whileInView={{ x: 0, opacity: 1 }}
    exit={{ x: 300, opacity: 0 }}
    transition={{ duration: 1.3, ease: "easeInOut" }}
    >

        <div  name="faq"
           className="mt-24 px-2 lg:px-16 min-[760px]:px-32  flex items-center flex-col mb-32 space-y-12 " 
         >
            <div 
              className="text-left px-5 font-bold text-5xl  flex flex-col items-start justify-between space-y-3">
                <div>
                <h3 className="text-4xl lg:text-5xl leading-normal font-sans font-extrabold tracking-tight ">
                 Frequently Asked <span className="text-mainColor">Questions</span>
             </h3>
                </div>
             </div>

           <div className="flex min-[1000px]:w-[850px]  text-white space-x-2 md:space-y-0 md:flex-row items-center justify-between">
              <div>

                {
                  accordianData.map((data, index) => {
                    return <AccordianItems 
                    key={index}
                    open={index === open}
                    title={data.title}
                    desc={data.desc || `<pre>`}
                    toggle={() => toggle(index)}
                    />
                  })
                }
              </div>

          
           </div>
         </div>
    </motion.div>
  )
}

export default Faq