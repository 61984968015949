import React from 'react';
//import footerlogo from "../assets/pgajlogo.png";
import { FaCrown } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import {Link as Linkroll} from 'react-scroll';
import { images } from '../constants';

const Footer = () => {
  return (
    
    <div className="w-full body-font bg-[#0c0904] text-gray-500">
        <div className="container flex flex-col flex-wrap px-5 py-24 mx-auto md:items-center lg:items-start md:flex-row-reverse md:flex-no-wrap">
            <div className="flex-shrink-0 w-64 mx-auto text-center md:mx-0  ">
                <a href="#" className="flex items-center justify-center font-medium text-gray-900 title-font  ">
                    <span><img src={images.logo} className=' brightness-50  w-[30px] lg:w-[40px] rounded-md mx-auto md:mx-0' alt='logo_footer'/></span>
                </a>
                <p className="mt-2 text-sm text-center text-neutral-300 uppercase font-semibold"> Security Network & Integrated Services Ltd.</p>
                <p className="mt-2 text-sm text-center ">providing superior and unparallel security services with enchanced quality for customer satisfaction and project delivery.</p>
                <div className="mt-4 ">
                    <span className="inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start">
                        <a target="_blank" href="#!" className=" cursor-pointer hover:text-eni_orange">
                            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round"strokeWidth="2"
                                className="w-5 h-5" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a>
                        <a target="_blank" href="#!" className="ml-3 cursor-pointer hover:text-eni_orange">
                           <FaXTwitter size={20}/>
                        </a>
                        <a target="_blank" href="#!" className="ml-3  cursor-pointer hover:text-eni_orange">
                            <svg fill="none" stroke="currentColor" strokeLinejoin="round" 
                                strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a target="_blank" href="#!" className="ml-3 cursor-pointer hover:text-eni_orange">
                            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round"
                                strokeLinejoin="round" strokeWidth="0" className="w-5 h-5" viewBox="0 0 24 24">
                                <path stroke="none"
                                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                </path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </span>
                </div>
            </div>
            <div className="flex flex-wrap flex-grow mt-10 -mb-10 text-center md:pl-20 md:mt-0 md:text-left">
                <div className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2 className={`mb-3 text-sm font-medium tracking-widest text-white uppercase title-font`}>About</h2>
                    <nav className="mb-10 list-none">
                        <li className="mt-3">
                            <Link to="/about"  className=" cursor-pointer hover:text-eni_orange">About us</Link>
                        </li>
                        <li className="mt-3">
                            <Linkroll to="features" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange">Why Choose Us</Linkroll>
                        </li>
                        
                    </nav>
                </div>
                <div className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2 className={`mb-3 text-sm font-medium tracking-widest text-white uppercase title-font`}> enquiry</h2>
                    <nav className="mb-10 list-none">
                        <li className="mt-3">
                            <Linkroll to="faq" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange">Check FaQ</Linkroll>
                        </li>
                        <li className="mt-3">
                            <Linkroll to="services" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange">Our Services</Linkroll>
                        </li>
                        <li className="mt-3">
                            <Linkroll to="whoweserve" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange">Who We Serve</Linkroll>
                        </li>
                      
                       
                    </nav>
                </div>
              
                <div className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2 className="mb-3 text-sm font-medium tracking-widest text-white  uppercase title-font">Contact</h2>
                    <nav className="mb-10 list-none">
                        <li className="mt-3">
                            <span className="font-bold">Email:</span> <br/>
                            info@detigerssecurity.com
                        </li>
                        <li className="mt-3">
                          <span className="font-bold">Tel:</span>
                          <br/> 08162090146, 09074581236, 07017099271
                        </li>
                    </nav>
                </div>
                <div className="w-full px-4 lg:w-1/4 md:w-1/2">
                    <h2 className="mb-3 text-sm font-medium tracking-widest text-white  uppercase title-font">Get Started</h2>
                    <nav className="mb-10 list-none">
                        <li className="mt-3">
                            <Linkroll to="works" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange"> How It Works</Linkroll>
                        </li>

                        <li className="mt-3">
                            <Link to="/contact" spy={true} smooth={true} offset={-150} className=" cursor-pointer hover:text-eni_orange">Reach Out</Link>
                        </li>
                    </nav>
                </div>

            </div>
        </div>
        <div className="bg-black/80 text-gray-300">
            <div className="container px-5 py-4 mx-auto">
                <p className="text-smcapitalize text-center  xl:text-center">© 2024 All rights reserved 
                <span className="text-eni_orange mx-2">|</span> DTSN </p> <br/>
                <div className="mx-auto justify-center text-center max-w-[400px]">

                <marquee className="mx-auto ">
                <div className="flex flex-row items-center space-x-2">
                <a href="https://davidlawrence.onrender.com" target="_blank"><span className='text-mainColor text-xs lg:text-sm font-bold justify-center hover:text-eni_orange '>David Lawrence</span> concepts </a>
                <FaCrown className="text-mainColor"/>

                </div>
                
                </marquee>
                </div>
                
                 
            </div>
        </div>
    </div>   
  )
}

export default Footer
