import React from 'react';
import TeamCard from './container/TeamCard';

const TeamPage = () => {
  return (
    <div>
    <TeamCard/>

    </div>
  )
}

export default TeamPage