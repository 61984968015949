import axios from "axios";

export const getAllOutreach = async () => {
  try {
    const { data, headers } = await axios.get(
      `/api/v1/outreach/getalloutreach/`
    );
    return { data, headers };
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};


export const createNewOutreach = async ({ image, public_id, description, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
         'Content-Type': 'multipart/form-data',      
      },
  }
    const { data } = await axios.post(
      `/api/v1/createoutreach`,
      {
        image, public_id, description, token
      }, config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const deletePost = async ({outreachId, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  }
    const { data } = await axios.delete(
      `/api/v1/deleteoutreach/${outreachId}`,
       config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const updatePost = async ({id, image, public_id, newDescription, token}) => {
  try {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`, 
      },
  }
    const { data } = await axios.put(
      `/api/v1/updatebyoutreach/${id}`,
      {
        image, public_id, newDescription, id, token
      }, config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};

export const getSinglePostV1 = async ({id}) => {
  try {
    const { data } = await axios.get(
      `/api/v1/outreach/${id}`
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message)
      throw new Error(error.response.data.message);
    throw new Error(error.message);
  }
};