import React from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import { useTheme } from '../../components/ThemeContext';
import {motion} from "framer-motion";
import Faq from '../../components/Faq';
import Services from './container/Services';
import OutreachStories from './container/OutreachStories';
import WhoWeServer from './container/WhoWeServer';
import HowItWorks from './container/HowItWorks';
import WhyChooseUs from './container/WhyChooseUs';

const LandingPage = () => {
  //const { darkMode, background } = useTheme();
 
  return (
  <div >
  <MainLayout  >
   <Hero />
   <Services />
   <WhyChooseUs/>
   <WhoWeServer/>
   <HowItWorks/>
  
   <OutreachStories/>

    <Faq/>

  </MainLayout>
  </div> 
  )  
}

export default LandingPage;
