import React from 'react';
import {FaBusinessTime , Fa} from "react-icons/fa";
import { MdGppGood , MdLinearScale} from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { IoHardwareChipSharp } from "react-icons/io5";


const WhyChooseUs = () => {
  return (
    <div className="mt-10 bg-gradient-to-t from-[#333333] to-[#000000]">
    <section id="features" className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-[#333333] ">
        <div className="relative mx-auto max-w-5xl text-center">
            <span className="font-sans text-gray-400 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
            Why choose us
            </span>
            <h2
                className=" font-sans block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl">
                We are an innovative, reliable, high-performing
            </h2>
            <p
                className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-400">
                Our differentia specifica - that which sets us apart from the competition - revolves around our security value proposition stated below.
            </p>
        </div>

        <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <FaBusinessTime width={40} className='text-eni_orange text-2xl '/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">BUSINESS-CENTRIC</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">We establish supportive mechanisms that aid our clients business success.
                </p>
            </div>
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <MdGppGood width={40} className='text-eni_orange text-2xl'/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">STANDARDIZED PROCESSES</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">Our operations build around global best practices.
                </p>
            </div>
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <AiFillInfoCircle width={40} className='text-eni_orange text-2xl '/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">SITUATIONAL UNDERSTANDING</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">We use a customized approach for each client borne out of awareness and clients’ needs.
                </p>
            </div>
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <MdLinearScale width={40} className='text-eni_orange text-2xl '/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">EVALUATIVE MEASURES</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">Our established matrices for constant evaluation ensures the attainment of terms of reference.
                </p>
            </div>
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <CgProfile width={40} className='text-eni_orange text-2xl '/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">SKILLED PROFESSIONALS</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">We possess the knowledge, skills, and attitudes (KSAs) to deliver responsive and responsible services.
                </p>
            </div>
            <div className="rounded-md border border-eni_orange bg-neutral-900/50 p-8 text-center shadow">
                <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
                    style={{'background-image': 'linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%); border-color: rgb(93, 79, 240)'}}>
                   <IoHardwareChipSharp width={40} className='text-eni_orange text-2xl '/>
                </div>
                <h3 className="mt-6 text-neutral-300 font-sans">INTEGRATED TECHNOLOGY</h3>
                <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">We deploy various security technology that aid asset protection and business processes.
                </p>
            </div>


           


           


        </div>

        <div className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
            style={{'background-image': 'linear-gradient(to right top, rgba(79, 70, 229, 0.2) 0%, transparent 50%, transparent 100%); border-color: rgba(92, 79, 240, 0.2)'}}>
        </div>
        <div className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
            style={{'background-image': 'linear-gradient(to left top, rgba(220, 38, 38, 0.2) 0%, transparent 50%, transparent 100%); border-color: rgba(92, 79, 240, 0.2)'}}>
        </div>

    </section>
</div>
  )
}

export default WhyChooseUs