import React from "react";
import {useState, useMemo } from "react";
import ArticleCard from "../../../components/ArticleCard";
import { useQuery } from "@tanstack/react-query";
import { getAllPosts } from "../../../services/posts";
import { toast } from "react-hot-toast";
import ArticleCardSkeleton from "../../../components/ArticleCardSkeleton";
import ErrorMessage from "../../../components/ErrorMessage";
import PaginationV1 from "../../../components/PaginationV1";


let PageSize = 5;
const Articles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isError } = useQuery({
    queryFn: () => getAllPosts(currentPage),
    queryKey: ["articles"],
    onError: (error) => {
      //toast.error(error.message);
      console.log(error);
    },
  });

  
  const currentTableData = 
    useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return  data?.data?.data?.slice(firstPageIndex, lastPageIndex);
    }, [data?.data?.data, currentPage]);
  
  


  return (
    <section className="flex flex-col mx-auto px-5 py-10 bg-gradient-to-b from-[#333333] opacity-90 to-[#969696]">
      <div className=" flex flex-wrap md:gap-x-5 gap-y-5 pb-10">
        {isLoading ? (
          [...Array(5)].map((item, index) => (
            <ArticleCardSkeleton
              key={index}
              className="w-full lg:w-[calc(24.5%-15px)] md:w-[calc(33.33%-21px)]"      
            />
          ))
        ) : isError ? (
          <ErrorMessage message="Couldn't fetch the posts data" />
        ) : (
          currentTableData.map((post) => (
            <ArticleCard
              
              key={post.articleId}
              post={post}
              className="w-full md:w-[calc(33.33%-21px)] lg:w-[calc(20%-21px)]"
            />
          ))
        )}
        
      </div>
     
      <PaginationV1
       
        className={`pagination-bar mx-auto text-neutral-300 `}
        currentPage={currentPage}
        totalCount={data?.data?.data?.length || 10} //data?.data?.data?.length
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
        />

    </section>
  );
};

export default Articles;
