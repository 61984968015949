import React from "react";
import { images } from "../../../constants";
import { motion } from "framer-motion";

const AboutIntro = () => {
  return (
    <>
      <section 
       className="overflow-hidden p-4 rounded-lg pb-12  lg:pb-[90px] ">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-start justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12 ">
              
                <div className="w-full px-3 sm:px-4 ">
                    <img
                      src={images.secretarylady}
                      alt=""
                      className="w-full rounded-2xl"
                    />
                </div>
                
              
            </div>

            <div className="rounded-bl-3xl rounded-tr-3xl mt-12 md:mt-0 w-full px-4 lg:w-1/2 xl:w-5/12 bg-gradient-to-tr from-[#333333] to-neutral-400/90">
              <div className="mt-10 lg:mt-0">
                <h2 className="mb-5  text-center  text-black sm:text-[40px]/[48px]">
                 
                  <span className="text-black font-sans text-3xl lg:text-5xl"> ABOUT US </span> 
                </h2>
                <div className="max-w-[700px] text-center mt-8">
                <p className="mb-12 text-neutral-300 text-justify">
                De Tigers Security Network and Integrated Services Limited is a private security company that specializes in Critical Infrastructure Protection.
                <br/><br/>Our Company was borne out of the need to address the growing rate of global insecurity and to improve business service provision for the protection of lives and property.
              <br/><br/>With De Tigers, you are very certain of quality service, professional conduct, and innovative solutions to security needs across several environments for commercial, institutional, residential and governmental clientele.
                </p>

                <ul type="i">
                  <li>
                  <p className="text-neutral-300 text-justify"><h2 className="text-neutral-200 font-semibold font-sans">VISION : </h2> Our vision is to grow the security market in Nigeria by providing cost-efficient security solutions, improved processes and enabling technology.</p>
                  </li>
                  <li className="mt-3">
                  <p className=" text-neutral-300 text-justify"><h2 className="text-neutral-200 font-semibold font-sans">MISSION : </h2> Our mission is to provide superior and unparallel security services with enchanced quality for customer satisfaction and project delivery.</p>
                  </li>

                  <li className="mt-3">
                  <h2 className="text-neutral-200 font-semibold font-sans">CORE VALUES: </h2>
                  <p className="mt-2 text-justify"><span className="font-sans font-semibold text-neutral-200">1. Teamwork & Collaboration: </span><span className=" text-neutral-300 text-justify"> We conduct are business in a collaborative environment where teams works together for the benefit of our clients.</span></p>
                  <p className="mt-2 text-justify"><span className="font-sans font-semibold text-neutral-200">2. Integrity & Truthfulness: </span><span className=" text-neutral-300 text-justify"> We insist on operating in line with ethical and honest business practices.</span></p> 
                  <p className="mt-2 text-justify"><span className="font-sans font-semibold text-neutral-200">3. Commitment & Goal-Oriented: </span><span className=" text-neutral-300 text-justify"> We follow through on our commitment and ensure their attainment of business goals and objectives. We driven by result.</span></p> 
                  <p className="mb-8 text-justify"><span className="font-sans font-semibold text-neutral-200">4. Novelty & Advancement: </span><span className=" text-neutral-300 text-justify"> We constantly think of new and better ways of doing things for the advancement of society.</span></p> 
                 

                  </li>

                </ul>
                
                </div>    
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutIntro;
