import man from '../assets/detigers/Man.jpg';
import man2 from '../assets/detigers/man2.jpg';
import lady from '../assets/detigers/Lady.jpg';
import ladyincar from '../assets/detigers/girlincar.jpg';
import tiger from '../assets/detigers/tiger.jpg';
import logo from '../assets/detigers/logo.png';
import logowithtext from '../assets/detigers/logowithtext.jpg';
import contact from '../assets/detigers/contactgirl.jpg';
import padlock from '../assets/detigers/padlock.jpg';
import drone from '../assets/detigers/drone.jpg';
import meetingroom from '../assets/detigers/meetingroom.jpg';
import organogram from '../assets/detigers/organogram.jpg';
import secretarylady from '../assets/detigers/secretarylady.jpg';






const images = {
    man, man2, lady, ladyincar, tiger, logo, logowithtext, contact, padlock, drone, meetingroom, organogram, secretarylady
}

export default images;