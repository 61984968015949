import React from 'react'
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import { Collapse } from "react-collapse"
import PropTypes from 'prop-types';


export default function AccordianItems({open, toggle, title, desc}) {
  return (
    <div className="pt-[10px] ">
       <div 
        className="py-[16px] px-[40px] rounded-t-md  hover:text-mainColor transition duration-300 bg-black/95 flex justify-between items-center cursor-pointer"
        onClick={toggle}
       >
         <p className="text-[13px] font-semibold">
            {title}
         </p>
         <div className="text-[14px]">
           {open ? <AiOutlineMinus /> : <AiOutlinePlus /> }
         </div>
       </div>

        <Collapse isOpened={open}>
            <div className="px-[50px] text-[13px] text-gray-100 font-medium pb-[20px] rounded-b-md  bg-black/80 ">
             
              {desc}
              
            </div>
        </Collapse>
    </div>
  )
}

AccordianItems.propTypes = {
    open: PropTypes.bool, 
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
  };



