import React from 'react';
import HeaderV1 from './HeaderV1';
//import Footer from './Footer';
import Footer from './Footer';
import Donate from './Donate';
import { useTheme } from './ThemeContext';
import ScrollToTopButton from './ScrollToTopButton';
import { motion } from 'framer-motion';

const MainLayout = ({children}) => {
 // const { darkMode, background } = useTheme();
  return (
    <div className="relative " >
      <HeaderV1 />
      <div className="md:pt-5  mt-16">
      {children}
      </div>

      <div >
        <Donate  />
      </div>
     
      <ScrollToTopButton />

      <motion.div initial={{ x: -200, opacity: 0 }}  whileInView={{ x: 0, opacity: 1 }} exit={{ x: 200, opacity: 0 }} transition={{ duration: 1.3, ease: "easeInOut" }}>
        <Footer />
      </motion.div>
      
    </div>
  )
}

export default MainLayout
