import React from 'react';
import MainLayout from '../../components/MainLayout';
import {AiOutlineMail, AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiOutlineTwitter, AiOutlineCompass} from "react-icons/ai";
import ContactAnime from './container/ContactAnime';
import {motion} from 'framer-motion';

const ContactPage = () => {
  return (
    <MainLayout> 
    <div className="container  mx-auto md:px-6 "> 
   
    <section className="mb-6">
   
      <div className="container px-6 md:px-12">
        
        <div
          className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  md:py-16 md:px-12  backdrop-blur-[30px]">
             <h1 className='font-sans font-bolder text-3xl lg:text-5xl mx-auto text-center mb-2'> CONTACT</h1>
          <div className="flex flex-wrap">
            {/** Left hand side */}
            <div className="mb-12 w-full  shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
              
            <ContactAnime/>

            </div>
            {/** Right hand side */}
            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12 text-black">
              <p className='text-center mb-12 '>Have a question or feedback? We're here to help! Reach out to us and let's connect:</p>
              <div className="flex flex-wrap">
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_green">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                          stroke="currentColor" className="h-6 w-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">
                      Phone Number
                      </p>
                      
                      <p className="text-neutral-500 ">
                      08162090146, 09074581236, 07017099271
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_green">
                      <AiOutlineMail size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">
                        Email
                      </p> 
                      <p className="text-neutral-500 max-w-[100px]">
                      <a href='mailto:info@detigerssecurity.com' target='_blank' rel="noreferrer"> info@detigerssecurity.com</a>
                      </p>
                    
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_green">
                      <AiOutlineCompass size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">
                        Address
                      </p> 
                      <p className="text-neutral-500 max-w-[200px]">
                      <a href='#!' rel="noreferrer"> 39/40 Peter Odili Road, Trans Amadi Industrial Layout, Port Harcourt</a>
                      </p>
                    
                    </div>
                  </div>
                </div>
                <div
                  className="mb-12 w-full shrink-0 grow-0 basis-auto md:mb-0 md:w-6/12 md:px-3 lg:mb-12 lg:w-full lg:px-6 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_green">
                      <AiFillLinkedin size={22}/>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">LinkedIn</p> 

                      <p className="text-neutral-500 ">
                        <a href='' target='_blank' rel="noreferrer">DeTigersSecurity</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:w-full lg:px-6 xl:mb-12 xl:w-6/12">
                  <div className="align-start flex">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-eni_green">
                      <AiFillFacebook size={22} />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold text-black">Facebook</p>
                      <p className="text-neutral-500 ">
                      <a href='' target='_blank' rel="noreferrer">DeTigersSecurity</a>
                      </p>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* :MAP CONTAINER */}
   
    <div className="container relative mt-16 rounded-lg shadow-2xl border-2 ">
              {/* Embed Google map */}
      
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.7901263244094!2d7.04648731064881!3d4.806061040714836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069cc57efa15285%3A0xa3b39b0286023aad!2s39%2C%2040%20Peter%20Odili%20Rd%2C%20Rainbow%20Town%2C%20Port%20Harcourt%20500101%2C%20Rivers!5e0!3m2!1sen!2sng!4v1712138636415!5m2!1sen!2sng"
              title="map" scrolling="no" frameborder="0"
              width="100%" height="450" 
              className="" 
              loading="lazy" />
            
      </div>
    </div>
    </MainLayout>  
  )
}

export default ContactPage
