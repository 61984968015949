import React from "react";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
                                        
const ArticleCard = ({ post, className}) => {
  return (
    <div
    className={`rounded-xl overflow-hidden shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] ${className}`}
  >
    <Link to={`/articles/${post.title}`}>
      <img
        src={
          post.image
           
        }
        alt="title"
        className="w-full object-cover object-center h-48 md:h-52 lg:h-48 xl:h-60 hover:scale-105 duration-200"
      />
    </Link>
    <div className="p-5">
    <div className="flex justify-between flex-nowrap items-center ">
       <span className="flex flex-row items-center text-eni_orange"> Verified <MdVerified className="text-eni_orange"/> </span>
        <span className={`font-bold  text-neutral-400 italic text-sm md:text-base`}>
          {new Date(post.createdOn).getDate()}{" "}
          {new Date(post.createdOn).toLocaleString("default", {
            month: "long",
          })}
        </span>
      </div>
      <Link to={`/articles/${post.title}`}>
        <h2 className={`font-sans font-semibold text-xl mt-6  text-neutral-300  text-ellipsis overflow-hidden ...`}>
          {post.title}
        </h2>  
      </Link>
      
    </div>
  </div>
  );
};

export default ArticleCard;
