import React from 'react';
import {Carousel } from 'antd';
import { useQuery } from "@tanstack/react-query";
import { getAllOutreach } from "../../../services/outreach";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ImageslideSkeleton from "../../../components/ImageslideSkeleton";
import ErrorMessage from "../../../components/ErrorMessage";



const OutreachStories = () => {

  const { data: outreachData, isLoading, isError } = useQuery({
    queryFn: () => getAllOutreach(),
    queryKey: ["outreaches"],
    onError: (error) => {
      console.log(error);
    },
  });

  return (
       
<div className="container my-4 mx-auto md:px-6">

<section className="mb-4 text-center">
  <h2 className="mb-12 text-3xl lg:text-5xl font-bold font-sans normal-leading">Gallery Showcase</h2>
    <Carousel 
    arrows={true}
    nextarrow={<FaArrowRight />} 
    prevarrow={<FaArrowLeft/>}
    autoplay
    draggable={true} 
    dots={true}
    dotPosition='top'
    autoplaySpeed={4000}>
      {isLoading ? (
          [...Array(4)].map((item, index) => (
            <ImageslideSkeleton
              key={index}
              className="w-full mx-auto lg:w-[calc(50%)] md:w-[calc(33.33%-21px)]"
              
            />
          ))
        ) : isError ? (
          <ErrorMessage message="Couldn't fetch the posts data" />
        ) : (
        outreachData?.data?.data.slice(0,10).map((post) => (
    
      <div key={post.outreachId} className="relative" >
      <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
      <div
        className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none "
        >
        <img className="mx-auto mb-6 rounded-lg shadow-lg dark:shadow-black/20 lg:w-[500px] w-[95vw] hover:scale-105 duration-200"
          src={post.image} alt="outreach story" />
        <div className='mx-auto max-w-[95vw] md:max-w-[500px] lg:max-w-[600px] text-black text-lg lg:text-xl rounded-sm bg-gradient-to-r from-[#333333] to-neutral-400/70'>
            <p className=' text-neutral-200 text-lg'>{post.description}</p>
        </div>
    </div>
    </div>
    </div>
      )))

    }  
    </Carousel>
    </section>
</div>
  )
}

export default OutreachStories