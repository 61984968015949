import React from 'react'

const Services = () => {
  return (
    <div name="services" className="container relative flex flex-col justify-between h-full max-w-6xl px-10 mx-auto xl:px-0 mt-5 lg:mt-0">
    <h2 className="mb-1 text-3xl lg:text-5xl font-extrabold font-sans leading-tight text-black">Our Services</h2>
    <p className="mb-12 text-lg text-neutral-400">Here is a few of the awesome Services we provide.</p>
    <div className="w-full">
        <div className="flex flex-col w-full mb-10 sm:flex-row">
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-indigo-500 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-indigo-500 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 uppercase font-sans">Contracted Guard Service</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-indigo-500 uppercase">------------</p>
                        <p className="mb-2 text-gray-600">We maintain and provide a ready and  professional guard force superior to our  competitors. <br/> Our Private Security Guards are contracted to provide security services within organizations and private properties.<br/><br/>
                        <span className='text-neutral-800 font-bold'>Their training includes over fifteen (15) areas:</span>
                        <ol typeof='1' className='mt-2 '>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>1. Access Control</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>2. Incident Response</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>3. Investigative Principles</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>4. Radio Communications</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>5. Crowd Control</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>6. Use of Force Principles</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>7. Traffic Control</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>8. Fundamental Human Rights</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>9. Etc.</li>
                        </ol>
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2">
                <div className="relative h-full ml-0 md:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-purple-500 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-purple-500 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 uppercase font-sans">Security Technology</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-purple-500 uppercase">------------</p>
                        <p className="mb-2 text-gray-600">We assist our clients in integrating  technology with security solutions   that aid the protective responsibilities  of outsourced or proprietary security  mechanisms. <br/> The use of security technology ensures  the prevention, detection, monitoring,  and response to adverse events. <br/><br/>
                        <span className='text-gray-800 font-bold'>These technologies includes:</span>
                        <ol typeof='1' className='mt-2 '>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>1. CCTV and Drone surveillance</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>2. Alarm system for burglary, re,  intrusion</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>3. Automatic Number Plate Recognition  (ANPR) systems</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>4. Tracking Devices and Geographic  Positioning Systems (GPS)</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>5. Access Control Doors and Turnstile.</li>
                            <li className='hover:text-neutral-400 hover:scale-105 duration-100 mt-1'>6. Etc.</li>
                        </ol>
                        </p>
                    </div> 
                </div>
            </div>
        </div>
        <div className="flex flex-col w-full mb-5 sm:flex-row">
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-blue-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-blue-400 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 font-sans uppercase">close protection</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-blue-400 uppercase">------------</p>
                        <p className="mb-2 text-gray-600 text-justify"> We also provide close protection  (bodyguard) services for VIPs/Executives  and their families on contract basics or  during one-time trips within certain areas. <br/>This package also includes, negotiations  for full spectrum transportation  (bulletproof/regular vehicles) and armed  support by conventional security agencies.
</p>
                    </div>
                </div>
            </div>
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-yellow-400 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 font-sans uppercase">Event Security</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-yellow-400 uppercase">------------</p>
                        <p className="mb-2 text-gray-600 text-justify">As part of event management support  service we provide the needed security  for successful events ranging from  social to sports, or formal events such  as conferences and seminars, meetings,  symposiums, etc. <br/>The essence of this service area is to  identify security threats and to put in  appropriate measures to ensure the  protection of attendees throughout the  event.
</p>
                    </div>
                </div>
            </div>
            <div className="w-full mb-10 sm:mb-0 sm:w-1/2">
                <div className="relative h-full ml-0 mr-0 sm:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-red-400 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-red-400 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 font-sans uppercase">Security Survelliance</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-red-400 uppercase">------------</p>
                        <p className="mb-2 text-gray-600 text-justify">We provide surveillance services to aid  the protection of organizational assets  (people, property, and information) and  business processes. <br/>
</p>
                    </div>
                </div>
            </div>
            <div className="w-full sm:w-1/2">
                <div className="relative h-full ml-0 md:mr-10">
                    <span className="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-green-500 rounded-lg"></span>
                    <div className="relative h-full p-5 bg-white border-2 border-green-500 rounded-lg">
                        <div className="flex items-center -mt-1">
                            <h3 className="my-2 ml-3 text-lg font-bold text-neutral-800 font-sans uppercase">CANINE (K-9)SERVICES</h3>
                        </div>
                        <p className="mt-3 mb-1 text-xs font-medium text-green-500 uppercase">------------</p>
                        <p className="mb-2 text-gray-600 text-justify">We also deploy trained dogs and  handlers to assists in the protective  responsibility within contracted security  environment. <br/>These canines (K-9s) aid the detection of  concealed contrabands, pursuit and  apprehension of eeing suspects,  intruders and criminals, as well as access  and crowd control.
</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

export default Services