import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Routes, Route, } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import LandingPage from './pages/home/LandingPage';
import LoginPage from './pages/login/LoginPage';
import AboutPage from './pages/about/AboutPage';
import TeamPage from './pages/team/TeamPage';
import ContactPage from './pages/contact/ContactPage';
import ArticlePage from './pages/article/ArticlePage';
import ArticleDetailPage from './pages/articleDetail/ArticleDetailPage';



import AdminLayout from "./pages/admin/AdminLayout";
import Admin from "./pages/admin/screens/Admin";


//articles and post
import PostTest from './pages/admin/screens/posts/PostTest';
import ManagePosts from "./pages/admin/screens/posts/ManagePosts";
import EditPostV2 from './pages/admin/screens/posts/EditPostV2';



//outreaches
import OutreachTest from './pages/admin/screens/outreach/OutreachTest';
import ManageOutreaches from "./pages/admin/screens/outreach/ManageOutreaches";
import EditOutreachV2 from './pages/admin/screens/outreach/EditOutreachV2';




//import { ThemeProvider } from './components/ThemeContext';
import ParticlesComponent from '../src/components/Particles';

function App() {
  
  return (
    <div className={`App `}>
        
       
        <ParticlesComponent id="particles" />
        <ScrollToTop/>
        <Routes>
          <Route index path="/" exact element={ <LandingPage/>} />
          <Route  path="/about" element={ <AboutPage/>} />
          <Route  path="/team" element={ <TeamPage/>} />
          <Route  path="/articles/:id" element={ <ArticleDetailPage/>} />
          <Route  path="/article" element={ <ArticlePage/>} />          
          <Route  path="/contact" element={ <ContactPage/>} />
          <Route path="/v1/dtsn/admin" element={<LoginPage />}> </Route>
          
          <Route path ="/admin" element={<AdminLayout/>} >
              <Route index element={<Admin/>} />
             
              <Route path="posts/new" element={<PostTest />} />
              <Route path="posts/manage" element={<ManagePosts />} />
              <Route path="posts/manage/edit/:id" element={<EditPostV2 />} />


              <Route path="outreach/new" element={<OutreachTest />} />
              <Route path="outreach/manage" element={<ManageOutreaches />} />
              <Route path="outreach/manage/edit/:id" element={<EditOutreachV2 />} />
          </Route>

        </Routes>
      <Toaster/>
      
    </div>
  );
}

export default App;
