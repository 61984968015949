import React from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../../constants';

const WhoWeServer = () => {
  return (
    
<section name="whoweserve" className="bg-green-500ray-50 pb-24 relative z-30">
    <div className="mx-auto   ">
        <div className="relative z-40 container mx-auto h-full lg:px-8">
            <div className="px-4 py-4  lg:px-8 lg:pt-20">
                <div className="grid gap-10 lg:grid-cols-2 lg:mx-24 mx-2">
                    <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">

                        <div className="max-w-xl mb-6">
                            <h2 className="max-w-lg mb-6  font-sans text-4xl md:text-4xl lg:text-6xl font-bold   leading-normal">
                                Key Sectors <br/> We Serve
                            </h2>

                        </div>
                        <div>
                            <Link to="/about" aria-label=""
                                className=" hover:text-eni_orange hover:font-bold inline-flex items-center justify-start w-full font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                                Learn more
                                <svg className="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                                    <path
                                        d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z">
                                    </path>
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full   -mx-4 lg:pl-10">
                        <div className="flex flex-col items-end px-3">
                            <img className="object-cover mb-6 rounded shadow-lg h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                                src={images.drone}
                                alt="who we serve"/>
                            <img className="object-cover w-20 h-20 rounded shadow-lg sm:h-32 xl:h-40 sm:w-32 xl:w-40"
                                src={images.ladyincar}
                                alt=" who we serve"/>
                        </div>
                        <div className="px-3">
                            <img className="object-cover w-40 h-40 rounded shadow-lg sm:hbg-green-500ray-50-64 xl:h-80 sm:w-full xl:w-80"
                                src={images.tiger}
                                alt="who we serve"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap mt-4 gap-2 lg:mx-24 mx-3">
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-yellow-500  transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-yellow-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-yellow-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Oil & Gas</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-red-500ed-500  transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-red-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-red-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Education</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-blue-reen-500 transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-blue-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-blue-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Telecommunications</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-indigo-500   transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-indigo-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-indigo-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Housing & Residential</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-green-500  transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-green-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-green-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  "> Healthcare</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-yellow-500  transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-yellow-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-yellow-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Commercial</h3>
                        </div>

                    </a>
                </div>
                <div className="group flex  flex-grow  ">
                    <a href="#!"
                        className="flex flex-grow  justify-between rounded-lg overflow-hidden bg-white p-5 py-2 shadow-lg text-black group-hover:text-red-500ed-500  transition-all duration-1000 ease-in-out relative z-30">
                        <div
                            className=" absolute  -top-0 left-0 w-5 h-0.5 group-hover:h-full group-hover:left-0 group-hover:w-1/2 bg-red-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>
                        <div
                            className=" absolute  -top-0 right-0 w-5 h-0.5 group-hover:h-full group-hover:right-0 group-hover:w-1/2 bg-red-500/20     z-10 transition-all duration-700 ease-in-out ">
                        </div>

                        <div className="relative">
                            <h3 className="p-0 m-0  ">Etc</h3>
                        </div>

                    </a>
                </div>
              

            </div>
        </div>

    </div>
    <div className="lg:text-[150px] varien absolute top-8 left-24 text-green-700/5  z-0  ">
        De Tigers Security 
    </div>
</section>
  )
}

export default WhoWeServer