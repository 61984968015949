import { Link ,useNavigate} from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { images } from "../../../../constants";
import { useEffect, useState } from "react";
import { AiFillDashboard, AiOutlineClose, AiOutlineMenu, AiOutlineLogout} from "react-icons/ai";
import { FaUser, FaImages } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import NavItem from "./NavItem";
import NavItemCollapse from "./NavItemCollapse";
import { logout } from "../../../../store/actions/user";
import { useDispatch } from "react-redux";


const MENU_ITEMS = [
  {
    title: "Dashboard",
    link: "/admin",
    icon: <AiFillDashboard className="text-xl" />,
    name: "dashboard",
    type: "link",
  },
  
  {
    title: "Post App News",
    content: [
      { title: "Add new Post", link: "/admin/posts/new" },
      { title: "Manage All Posts", link: "/admin/posts/manage" },
    ],
    icon: <MdDashboard className="text-xl" />,
    name: "posts",
    type: "collapse",
  },
  {
    title: "Image Show",
    content: [
      { title: "Add Image", link: "/admin/outreach/new" },
      { title: "Manage Images", link: "/admin/outreach/manage" },
    ],
    icon: <FaImages className="text-xl" />,
    name: "Images",
    type: "collapse",
  },
  {
    title: "Profile",
    link: "#",
    icon: <FaUser className="text-xl" />,
    name: "profile",
    type: "link",
  },
  
];

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeNavName, setActiveNavName] = useState("dashboard");
  const windowSize = useWindowSize();

  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  useEffect(() => {
    if (windowSize.width < 1024) {
      setIsMenuActive(false);
    } else {
      setIsMenuActive(true);
    }
  }, [windowSize.width]);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logoutHandler=()=>{
    dispatch(logout())
    navigate("/login")
  }

  return (
    <header className="flex h-fit w-full items-center justify-between p-4 lg:h-full lg:max-w-[300px] lg:flex-col lg:items-start lg:justify-start lg:p-0">
      {/* logo */}
      <Link to="/">
      <div className='mt-2 lg:hidden'>
      <img src={images.logo} className="w-12 h-12" alt="logo"/>
      </div>
      </Link>
      {/* menu burger icon */}
      <div className="cursor-pointer lg:hidden">
        {isMenuActive ? (
          <AiOutlineClose className="w-6 h-6 text-eni_orange" onClick={toggleMenuHandler} />
        ) : (
          <AiOutlineMenu className="w-6 h-6 text-eni_orange" onClick={toggleMenuHandler} />
        )}
      </div>
      {/* sidebar container */}
      {isMenuActive && (
        <div className="fixed inset-0 lg:static lg:h-full lg:w-full">
          {/* underlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 lg:hidden"
            onClick={toggleMenuHandler}
          />
          {/* sidebar */}
          <div className="fixed top-0 bottom-0 left-0  w-3/4  bg-white p-4 lg:static lg:h-full lg:w-full lg:p-6">
            <Link to="/">
            <div className='mt-2'>
            <img src={images.logo} className="w-12 h-12 md:w-18 md:h-18" alt="logo"/>
            </div>
            </Link>
            <h4 className="mt-10 font-bold text-[#969696]">MAIN MENU</h4>
            {/* menu items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              {MENU_ITEMS.map((item) =>
                item.type === "link" ? (
                  <NavItem
                    key={item.title}
                    title={item.title}
                    link={item.link}
                    icon={item.icon}
                    name={item.name}
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                  />
                ) : (
                  <NavItemCollapse
                    key={item.title}
                    title={item.title}
                    content={item.content}
                    icon={item.icon}
                    name={item.name}
                    activeNavName={activeNavName}
                    setActiveNavName={setActiveNavName}
                  />
                )
              )}
              <button onClick={logoutHandler} className="text-black text-3xl fixed bottom-0"><AiOutlineLogout/></button>
            </div>
            
          </div>
          
        </div>
       
      )}
       
    </header>
  );
};

export default Header;
